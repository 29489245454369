<template>
  <div class="page-content">
    <page-breadcrumb title="Chat To AI" class="mb-2" />
    <section class="chat-app-window">
      <div class="mb-1 d-flex">
        <multi-select id="prompt_select" placeholder="Select prompt" class="mr-1" :disabled="!!session_id"
          :multiple="false" v-model="prompt_id" :options="prompt_options"
        />
        <loading-button v-show="!session_id" class="flex-1" style="min-width: 100px;" text="Start" icon="LogInIcon" variant="relief-primary"
          :is-loading="start_loading" @click="startSession"
          :disabled="!prompt_id"
        />
        <loading-button v-show="!!session_id" class="flex-1" style="min-width: 150px;" text="End Session" icon="LogOutIcon" variant="gradient-warning"
          :is-loading="result_loading" @click="endSession"
          :disabled="!prompt_id"
        />
      </div>
      <section v-if="!!result" class="mt-3 border p-1">
        <div v-if="!!result.overall">
          <h3 class="text-center">Overall</h3>
          <b-card>
            <p>vocab_level: {{ result.overall.vocab_level }}</p>
            <p>vocab_level_value: {{ result.overall.vocab_level_value }}</p>
            <p>vocab_level_5_point_scale: {{ result.overall.vocab_level_5_point_scale }}</p>
            <p>grammar_level: {{ result.overall.grammar_level }}</p>
            <p>grammar_level_value: {{ result.overall.grammar_level_value }}</p>
            <p>grammar_level_5_point_scale: {{ result.overall.grammar_level_5_point_scale }}</p>
            <p>pronunciation_score: {{ result.overall.pronunciation_score }}</p>
            <p>pronunciation_score_5_point_scale: {{ result.overall.pronunciation_score_5_point_scale }}</p>
            <p>fluency_score_score_5_point_scale: {{ result.overall.fluency_score_score_5_point_scale }}</p>
            <p>speaking_rate_wpm: {{ result.overall.speaking_rate_wpm }}</p>
            <b-table v-if="level_result" :items="level_result.result" :fields="[
              { label: 'Level', key: 'level' },
              { label: 'Number of words', key: 'number_of_words' },
              { label: '% of total', key: 'total' },
              { label: 'Words', key: 'word_list' },
            ]" />
          </b-card>
        </div>
        <div v-if="!!result.detail">
          <h3 class="text-center">Vocab</h3>
          <b-card v-for="(item) in result.detail" :key="item.vocab.overall_level">
            <h4>{{ item.vocab.words.map(item => item.word).join(' ') }}</h4>
            <p>overall_level: {{ item.vocab.overall_level }}</p>
            <p>overall_level_fraction: {{ item.vocab.overall_level_fraction }}</p>
          </b-card>
        </div>
      </section>
      <b-card class="active-chat" v-show="session_id">
        <!-- User Chat Area -->
        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150 }" style="height: 100vh; max-height: calc(100vh - 400px)"
          class="user-chats scroll-area"
          id="chat_log_container" ref="chat_log_container"
        >
          <chat-log :chat-logs="chatLogs" :contact_bot="contact_bot" :contact_user="contact_user" />
        </vue-perfect-scrollbar>

        <!-- Message Input -->

        <div>
          <div v-if="res_waiting" class="d-flex justify-content-center">
            <div class="loadingio-spinner-ellipsis-81dtuvwecta">
              <div class="ldio-cc4671d81hd">
              <div></div><div></div><div></div><div></div><div></div>
              </div></div>
          </div>

          <b-row v-else class="mt-1 align-items-center">
            <b-col>
              <b-form-input :disabled="res_waiting"
                v-model="user_input"
                placeholder="Enter your message"
                @keyup.enter="sendMessage(user_input)"
              />
            </b-col>
            <loading-button v-show="!!session_id" class="ml-auto mr-50 btn-icon" icon="AlertCircleIcon" text="?" variant="gradient-info"
              @click="getSuggestion"
            />
            <audio-recorder class="ml-auto" @recorded="recordAudio" />
          </b-row>
        </div>

      </b-card>
    </section>
  </div>
</template>

<script>
import { safeGet, createUid } from '@core/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatLog from './_components/ChatLog.vue'
import AudioRecorder from '@core/components/audio-recorder/AudioRecorder.vue'
import service from '../service'
import prompt_service from '@/modules/ai/prompt/service'

let contact_bot = {
  id: 2,
  avatar: 'https://goga.ai/wp-content/uploads/2022/01/cropped-LOGO-APP-192x192.png'
}
let contact_user = {
  id: Date.now(),
};
export default {
  components:{
    ChatLog,
    VuePerfectScrollbar,
    AudioRecorder,
  },
  data(){
    return {
      user_input: null,
      chatLogs: [],
      contact_bot,
      contact_user,
      prompt_options: [],
      prompt_id: null,
      res_waiting: false,
      start_loading: false,
      result_loading: false,
      session_id: null,
      result: null,
      level_result: null,
    }
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/userData"];
    },
  },
  watch: {
    prompt_id() {
      this.contact_user.id = this.prompt_id + '--' + createUid();
    },
  },
  created() {
    // this.chatLogs.push(this.createBotMessage('hello!'));
    this.getPrompts();
  },
  methods:{
    async startSession() {
      this.start_loading = true;
      let data = await service.startSession({
        user_info: {
          "first_name": "user",
          "last_name": "cms-admin",
          "age": 0,
          "cefr_level": "Undefined",
          "l1_lang": "vie"
        },
        prompt_id: this.prompt_id,
      }).catch(err => {
        this.start_loading = false;
        return;
      });
      this.start_loading = false;
      this.session_id = data.session_id;
      this.pushResponseMessage(data.tutor_messages);
    },
    async endSession() {
      this.result_loading = true;
      let data = await service.endSession(this.session_id).catch(error => {
        this.result_loading = false;
      });
      this.result = data;
      this.session_id = null;
      this.result_loading = false;
      this.calculateResult();
    },
    calculateResult() {
      let result = {
        '-1': { level: 'Unclassified', level_number: -1, total_count: 0, word_list: [], },
        '0': { level: 'A1', level_number: 0, total_count: 0, word_list: [], },
        '1': { level: 'A2', level_number: 1, total_count: 0, word_list: [], },
        '2': { level: 'B1', level_number: 2, total_count: 0, word_list: [], },
        '3': { level: 'B2', level_number: 3, total_count: 0, word_list: [], },
        '4': { level: 'C1', level_number: 4, total_count: 0, word_list: [], },
        '5': { level: 'C2', level_number: 5, total_count: 0, word_list: [], },
      };
      let total_count = 0;
      for (const message of this.result.detail) {
        for (const word of message.vocab.words) {
          let existed_level = result[word.level];
          if(existed_level) {
            existed_level.total_count += 1;
            total_count += 1;
            let word_list = existed_level.word_list;
            let existed_word = word_list.find(word => word === word.word);
            if(!existed_word) {
              word_list.push(word.word);
            }
          }
        }
      }
      let result_list = [];
      Object.entries(result).forEach(([key, value]) => {
        result_list.push({
          level: value.level,
          number_of_words: value.total_count,
          total: Math.floor(value.total_count/total_count*100),
          word_list: value.word_list.join(', '),
        });
      });
      this.level_result = {
        total_count,
        result: result_list,
      };
    },
    async getPrompts() {
      let res = await prompt_service.getList();
      let prompts = safeGet(() => res.list) || [];
      this.prompt_options = prompts.map(item => {
        return {
          text: item.title,
          value: item._id,
        };
      });
    },
    async sendMessage(message) {
      this.user_input = null;
      this.pushMessage(this.createUserMessage(message));
      let payload = {
        sender: this.contact_user.id,
        prompt_id: this.prompt_id,
        message,
      }
      this.res_waiting = true;
      let data = await service.sendTextMessage(this.session_id, payload);
      this.res_waiting = false;
      this.pushResponseMessage(data.tutor_responses);
    },
    async sendMessageSpeech(transcript, message_id) {
      this.user_input = null;
      this.pushMessage(this.createUserMessage(transcript));
      let payload = {
        message_id,
        transcript,
      };
      this.res_waiting = true;
      let data = await service.sendTextMessageSpeech(this.session_id, payload);
      this.res_waiting = false;
      this.pushResponseMessage(data.tutor_responses);
    },
    pushMessage(message) {
      this.chatLogs.push(message);
      this.scrollChatlog();
    },
    createMessage(message, { is_user = true, type = 'text' } = {}) {
      return {
        id: createUid(),
        sender_id: is_user ? this.contact_user.id : this.contact_bot.id,
        time: Date.now(),
        message,
        type,
      }
    },
    createBotMessage(message, { type = 'text' } = {}) {
      return this.createMessage(message, { is_user: false, type });
    },
    createUserMessage(message, { type = 'text' } = {}) {
      return this.createMessage(message, { is_user: true, type });
    },
    async recordAudio(file) {
			let data = new FormData();
			data.append("audio_file", file);
      data.append("sender", this.contact_user.id);
      data.append("prompt_id", this.prompt_id);
      let file_url = URL.createObjectURL(file);
      this.pushMessage(this.createUserMessage(file_url, { type: 'audio' }));
      this.res_waiting = true;
			let audio_res = await service.decodeAudio(this.session_id, data);
      await this.sendMessageSpeech(audio_res.transcript, audio_res.message_id);
      this.res_waiting = false;
    },
    pushResponseMessage(tutor_responses, { voice_chat = false } = {}) {
      if(!tutor_responses.length) { return; }
      let message_index = voice_chat ? 1 : 0;
      let text_recognize = safeGet(() => tutor_responses[0].custom.speech_recognition_result.data.results[0].alternatives[0].transcript) || null;
      let bot_text = safeGet(() => tutor_responses[message_index].text) || null;
      let bot_audio = safeGet(() => tutor_responses[message_index].audio) || null;
      if(text_recognize) {
        this.pushMessage(this.createUserMessage(text_recognize));
      }
      this.pushMessage(this.createBotMessage(bot_audio, { type: 'audio' }));
      this.pushMessage(this.createBotMessage(bot_text));
    },
    scrollChatlog() {
      let container = this.$refs.chat_log_container.$el;
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 100);
    },
    async getSuggestion() {
      this.res_waiting = true;
      let data = await service.getSuggestion(this.session_id);
      this.res_waiting = false;
      this.pushResponseMessage(data.tutor_responses);
    },
  }
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

.user-chats {
  background-color: #111 !important;
  background-image: url(https://goga.ai/wp-content/themes/flatsome/assets/img/effects/sparkle1.png);
  background-size: initial;
}
</style>

<style type="text/css">
@keyframes ldio-cc4671d81hd {
  0% {
    transform: translate(12.4px, 54.56px) scale(0);
  }

  25% {
    transform: translate(12.4px, 54.56px) scale(0);
  }

  50% {
    transform: translate(12.4px, 54.56px) scale(1);
  }

  75% {
    transform: translate(54.56px, 54.56px) scale(1);
  }

  100% {
    transform: translate(96.72px, 54.56px) scale(1);
  }
}

@keyframes ldio-cc4671d81hd-r {
  0% {
    transform: translate(96.72px, 54.56px) scale(1);
  }

  100% {
    transform: translate(96.72px, 54.56px) scale(0);
  }
}

@keyframes ldio-cc4671d81hd-c {
  0% {
    background: #e15b64
  }

  25% {
    background: #abbd81
  }

  50% {
    background: #f8b26a
  }

  75% {
    background: #f47e60
  }

  100% {
    background: #e15b64
  }
}

.ldio-cc4671d81hd div {
  position: absolute;
  width: 14.879999999999999px;
  height: 14.879999999999999px;
  border-radius: 50%;
  transform: translate(54.56px, 54.56px) scale(1);
  background: #e15b64;
  animation: ldio-cc4671d81hd 1.0204081632653061s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-cc4671d81hd div:nth-child(1) {
  background: #f47e60;
  transform: translate(96.72px, 54.56px) scale(1);
  animation: ldio-cc4671d81hd-r 0.25510204081632654s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-cc4671d81hd-c 1.0204081632653061s infinite step-start;
}

.ldio-cc4671d81hd div:nth-child(2) {
  animation-delay: -0.25510204081632654s;
  background: #e15b64;
}

.ldio-cc4671d81hd div:nth-child(3) {
  animation-delay: -0.5102040816326531s;
  background: #f47e60;
}

.ldio-cc4671d81hd div:nth-child(4) {
  animation-delay: -0.7653061224489796s;
  background: #f8b26a;
}

.ldio-cc4671d81hd div:nth-child(5) {
  animation-delay: -1.0204081632653061s;
  background: #abbd81;
}

.loadingio-spinner-ellipsis-81dtuvwecta {
  width: 124px;
  height: 72px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-cc4671d81hd {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-cc4671d81hd div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */</style>
