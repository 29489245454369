<template>
  <div class="chats">
    <div
      v-for="(msg_group, index) in groupedLogs"
      :key="msg_group.sender_id+String(index)"
      class="chat"
      :class="{'chat-left': msg_group.sender_id !== contact_user.id}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msg_group.sender_id !== contact_user.id ? contact_bot.avatar : null"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="(msg_data) in msg_group.messages"
          :key="msg_data.id"
          class="chat-content"
        >
          <p v-if="msg_data.type === 'text'">{{ msg_data.message }}</p>
          <audio v-else-if="msg_data.type === 'audio'" autoplay :src="msg_data.message" preload="auto" controls :title="`audio_${Math.random()}.wav`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    chatLogs: { type: Array, required: true },
    contact_bot: { type: Object, required: true },
    contact_user: { type: Object, required: true },
  },
  data() {
    return {
    }
  },
  computed: {
    groupedLogs() {
      let groups = [];
      this.chatLogs.forEach(item => {
        let latest_group = groups[groups.length - 1];
        if(latest_group && item.sender_id === latest_group.sender_id) {
          latest_group.messages.push({...item});
        } else {
          groups.push({
            sender_id: item.sender_id,
            messages: [{ ...item }],
          })
        }
      });
      return groups;
    }
  },

}
</script>

<style>

</style>
