import axios from 'axios'
const api = axios.create({
  baseURL: process.env.VUE_APP_AI_SPEECH_ENDPOINT || 'http://103.141.140.192:9010',
})
let access_token = 'b34f32bd450e47e882c7ce9f9985a458';
export default {
  async speechRecognize(payload) {
    const response = await api.post(`/api/v1/ai/speech-recognize/rest?access_token=${access_token}`, payload)
    return response
  },
  async voiceChat(payload, prompt_id) {
    const response = await api.post(`/api/v1/ai/ai-tutor/voice-chat/rest`, payload, {
      params: { access_token, prompt_id },
    })
    return response
  },
  async textChat(payload, prompt_id) {
    const response = await api.post(`/api/v1/ai/ai-tutor/chat/rest`, payload, {
      params: { access_token, prompt_id },
    })
    return response
  },
  async startSession(payload) {
    const response = await api.post(`/api/v2/ai/ai-tutor/conversation`, payload, {
      params: { access_token },
    })
    return response?.data?.data;
  },
  async endSession(session_id) {
    const response = await api.patch(`/api/v2/ai/ai-tutor/conversation/${session_id}/state`, { state: "USER_COMPLETED", "reason": "" }, {
      params: { access_token },
    })
    return response?.data;
  },
  async sendTextMessage(session_id, payload) {
    const response = await api.post(`/api/v2/ai/ai-tutor/conversation/${session_id}/messages`, payload, {
      params: { access_token },
    })
    return response?.data?.data;
  },
  async sendTextMessageSpeech(session_id, payload) {
    const response = await api.post(`/api/v2/ai/ai-tutor/conversation/${session_id}/decoded_speech_messages`, payload, {
      params: { access_token },
    })
    return response?.data?.data;
  },
  async decodeAudio(session_id, payload) {
    const response = await api.post(`/api/v2/ai/ai-tutor/conversation/${session_id}/speech`, payload, {
      params: { access_token },
    })
    return response?.data?.data;
  },
  async getSuggestion(session_id) {
    const response = await api.get(`/api/v2/ai/ai-tutor/conversation/${session_id}/help/response_suggestion`, {
      params: { access_token },
    })
    return response?.data?.data;
  },

}
